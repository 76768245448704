export const mockCars = [
    {
        id: 1,
        name: 'Fortuner 2.4G AT (4x2)',
        images: [
            'https://www.toyotasaigon.com/images/usedCar/2024/63858642213219788824DC5F5F-DB0E-4212-AABD-901E8607341E.jpeg',
            'https://www.toyotasaigon.com/images/usedCar/2024/6385864221573139330BAB3D4B-54C8-49EE-9278-0B923E9FD292.jpeg',
        ],
        deposit: true,
        price: '8xx',
        discount: '15tr',
        color: 'Xám',
        location: 'Ho Chi Minh City',
        year: 2022,
        gearbox: 'Tự động',
        fuel: 'Xăng',
        seats: 5,
        engine: 1798,
    },
    {
        id: 2,
        name: 'Fortuner 2.4AT (4x2)',
        images: [
            'https://www.toyotasaigon.com/images/usedCar/2024/638551688142345361z5540164641779_021eefac33a4565a2b18f4b0c21604af.jpg',
            'https://www.toyotasaigon.com/images/usedCar/2024/638551688383209784z5540164668246_c7dfaa205113cf8f446e215ec25a9e21.jpg',
        ],
        discount: '15tr',
        price: '9xx',
        color: 'Trắng',
        location: 'Hanoi',
        year: 2021,
        gearbox: 'Tự động',
        fuel: 'Xăng',
        seats: 5,
        engine: 1997,
    },
    {
        id: 3,
        name: 'Mazda CX-5',
        images: [
            'https://www.toyotasaigon.com/images/usedCar/2024/638551688812522538z5538128579952_841232ba9629923e1a92468f93bd4982.jpg',
            'https://www.toyotasaigon.com/images/usedCar/2024/638551688980222833z5538128582984_5f468d21fd7fbc5761b282a3a5e1688f.jpg',
        ],
        price: '9xx',
        color: 'Đỏ',
        location: 'Da Nang',
        year: 2023,
        gearbox: 'Tự động',
        fuel: 'Xăng',
        seats: 5,
        engine: 2488,
    },
    {
        id: 4,
        name: 'FORTUNER MÁY DẦU AT 4x2',
        images: [
            'https://www.toyotasaigon.com/images/usedCar/2024/6385101427967301251.jpg',
            'https://www.toyotasaigon.com/images/usedCar/2024/6385101429074903192.jpg',
        ],
        price: '8xx',
        color: 'Xanh',
        location: 'Can Tho',
        year: 2020,
        gearbox: 'Tự động',
        fuel: 'Xăng',
        seats: 5,
        engine: 1999,
    },
    {
        id: 5,
        name: 'Fortuner 2.4G AT (4x2)',
        images: [
            'https://www.toyotasaigon.com/images/usedCar/2024/63858642213219788824DC5F5F-DB0E-4212-AABD-901E8607341E.jpeg',
            'https://www.toyotasaigon.com/images/usedCar/2024/6385864221573139330BAB3D4B-54C8-49EE-9278-0B923E9FD292.jpeg',
        ],
        price: '8xx',
        color: 'Xám',
        location: 'Ho Chi Minh City',
        year: 2022,
        gearbox: 'Tự động',
        fuel: 'Xăng',
        seats: 5,
        engine: 1798,
    },
    {
        id: 6,
        name: 'Fortuner 2.4AT (4x2)',
        images: [
            'https://www.toyotasaigon.com/images/usedCar/2024/638551688142345361z5540164641779_021eefac33a4565a2b18f4b0c21604af.jpg',
            'https://www.toyotasaigon.com/images/usedCar/2024/638551688383209784z5540164668246_c7dfaa205113cf8f446e215ec25a9e21.jpg',
        ],
        price: '9xx',
        color: 'Trắng',
        location: 'Hanoi',
        year: 2021,
        gearbox: 'Tự động',
        fuel: 'Xăng',
        seats: 5,
        engine: 1997,
    },
    {
        id: 7,
        name: 'Mazda CX-5',
        images: [
            'https://www.toyotasaigon.com/images/usedCar/2024/638551688812522538z5538128579952_841232ba9629923e1a92468f93bd4982.jpg',
            'https://www.toyotasaigon.com/images/usedCar/2024/638551688980222833z5538128582984_5f468d21fd7fbc5761b282a3a5e1688f.jpg',
        ],
        price: '9xx',
        color: 'Đỏ',
        location: 'Da Nang',
        year: 2023,
        gearbox: 'Tự động',
        fuel: 'Xăng',
        seats: 5,
        engine: 2488,
    },
    {
        id: 8,
        name: 'FORTUNER MÁY DẦU AT 4x2',
        images: [
            'https://www.toyotasaigon.com/images/usedCar/2024/6385101427967301251.jpg',
            'https://www.toyotasaigon.com/images/usedCar/2024/6385101429074903192.jpg',
        ],
        price: '8xx',
        color: 'Xanh',
        location: 'Can Tho',
        year: 2020,
        gearbox: 'Tự động',
        fuel: 'Xăng',
        seats: 5,
        engine: 1999,
    },
    {
        id: 9,
        name: 'Fortuner 2.4G AT (4x2)',
        images: [
            'https://www.toyotasaigon.com/images/usedCar/2024/63858642213219788824DC5F5F-DB0E-4212-AABD-901E8607341E.jpeg',
            'https://www.toyotasaigon.com/images/usedCar/2024/6385864221573139330BAB3D4B-54C8-49EE-9278-0B923E9FD292.jpeg',
        ],
        price: '8xx',
        color: 'Xám',
        location: 'Ho Chi Minh City',
        year: 2022,
        gearbox: 'Tự động',
        fuel: 'Xăng',
        seats: 5,
        engine: 1798,
    },
    {
        id: 10,
        name: 'Fortuner 2.4AT (4x2)',
        images: [
            'https://www.toyotasaigon.com/images/usedCar/2024/638551688142345361z5540164641779_021eefac33a4565a2b18f4b0c21604af.jpg',
            'https://www.toyotasaigon.com/images/usedCar/2024/638551688383209784z5540164668246_c7dfaa205113cf8f446e215ec25a9e21.jpg',
        ],
        price: '9xx',
        color: 'Trắng',
        location: 'Hanoi',
        year: 2021,
        gearbox: 'Tự động',
        fuel: 'Xăng',
        seats: 5,
        engine: 1997,
    },
    {
        id: 11,
        name: 'Mazda CX-5',
        images: [
            'https://www.toyotasaigon.com/images/usedCar/2024/638551688812522538z5538128579952_841232ba9629923e1a92468f93bd4982.jpg',
            'https://www.toyotasaigon.com/images/usedCar/2024/638551688980222833z5538128582984_5f468d21fd7fbc5761b282a3a5e1688f.jpg',
        ],
        price: '9xx',
        color: 'Đỏ',
        location: 'Da Nang',
        year: 2023,
        gearbox: 'Tự động',
        fuel: 'Xăng',
        seats: 5,
        engine: 2488,
    },
    {
        id: 12,
        name: 'FORTUNER MÁY DẦU AT 4x2',
        images: [
            'https://www.toyotasaigon.com/images/usedCar/2024/6385101427967301251.jpg',
            'https://www.toyotasaigon.com/images/usedCar/2024/6385101429074903192.jpg',
        ],
        price: '8xx',
        color: 'Xanh',
        location: 'Can Tho',
        year: 2020,
        gearbox: 'Tự động',
        fuel: 'Xăng',
        seats: 5,
        engine: 1999,
    },
    {
        id: 8,
        name: 'FORTUNER MÁY DẦU AT 4x2',
        images: [
            'https://www.toyotasaigon.com/images/usedCar/2024/6385101427967301251.jpg',
            'https://www.toyotasaigon.com/images/usedCar/2024/6385101429074903192.jpg',
        ],
        price: '8xx',
        color: 'Xanh',
        location: 'Can Tho',
        year: 2020,
        gearbox: 'Tự động',
        fuel: 'Xăng',
        seats: 5,
        engine: 1999,
    },
    {
        id: 9,
        name: 'Fortuner 2.4G AT (4x2)',
        images: [
            'https://www.toyotasaigon.com/images/usedCar/2024/63858642213219788824DC5F5F-DB0E-4212-AABD-901E8607341E.jpeg',
            'https://www.toyotasaigon.com/images/usedCar/2024/6385864221573139330BAB3D4B-54C8-49EE-9278-0B923E9FD292.jpeg',
        ],
        price: '8xx',
        color: 'Xám',
        location: 'Ho Chi Minh City',
        year: 2022,
        gearbox: 'Tự động',
        fuel: 'Xăng',
        seats: 5,
        engine: 1798,
    },
    {
        id: 10,
        name: 'Fortuner 2.4AT (4x2)',
        images: [
            'https://www.toyotasaigon.com/images/usedCar/2024/638551688142345361z5540164641779_021eefac33a4565a2b18f4b0c21604af.jpg',
            'https://www.toyotasaigon.com/images/usedCar/2024/638551688383209784z5540164668246_c7dfaa205113cf8f446e215ec25a9e21.jpg',
        ],
        price: '9xx',
        color: 'Trắng',
        location: 'Hanoi',
        year: 2021,
        gearbox: 'Tự động',
        fuel: 'Xăng',
        seats: 5,
        engine: 1997,
    },
    {
        id: 11,
        name: 'Mazda CX-5',
        images: [
            'https://www.toyotasaigon.com/images/usedCar/2024/638551688812522538z5538128579952_841232ba9629923e1a92468f93bd4982.jpg',
            'https://www.toyotasaigon.com/images/usedCar/2024/638551688980222833z5538128582984_5f468d21fd7fbc5761b282a3a5e1688f.jpg',
        ],
        price: '9xx',
        color: 'Đỏ',
        location: 'Da Nang',
        year: 2023,
        gearbox: 'Tự động',
        fuel: 'Xăng',
        seats: 5,
        engine: 2488,
    },
];
